<template>
  <div class="border border-solid border-blue-200">
    <div class="flex flex-col">
      <div class="duration-300">
        <datatable
          :data="settlements.data"
          :columns="columns"
          :fillable="false"
          :loading="settlements.loading"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    query: String,
  },
  data() {
    return {
      settlements: this.$options.resource([]),
      columns: [
        {
          name: "reference",
          th: "Reference",
        },
        {
          name: "merchant",
          th: "Merchant",
          render: (settlement) => settlement?.merchant?.business_name,
        },
        {
          name: "type",
          th: "Commission Type",
        },
        {
          name: "commission",
          th: "Commission",
          render: (settlement) =>
            settlement?.type === "percentage"
              ? `${settlement?.commission}%`
              : settlement?.commission,
        },
        {
          name: "amount",
          th: "Amount",
          render: (settlement) =>
            this.$options.filters.formatAmount(settlement?.amount),
        },
        {
          name: "cashback",
          th: "Cashback",
          render: (settlement) =>
            this.$options.filters.formatAmount(settlement?.cashback),
        },
        {
          name: "status",
          th: "Status",
          render: (settlement) => {
            return `<div class="badge badge-green-soft-outline px-7">
                  ${settlement?.status}
                </div>`
          }
        },
        {
          name: 'paid_at',
          th: 'Paid At',
          render: (settlement) =>
            this.$moment(settlement?.paid_date).format('dddd, MMMM Do YYYY'),
        },
      ],
    };
  },
  beforeMount() {
    this.paidSettlement();
  },
  methods: {
    async paidSettlement() {
      this.settlements.loading = true;
      await this.sendRequest("admin.merchants.settlements.paid", {
        success: (response) => {
          this.settlements.data = response.data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.settlements.loading = false;
    },
  },
};
</script>